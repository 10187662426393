<template>
  <div>
    <titulo-plano-contas
      @cadastrar="openModal"
      @gerar-plano-contas="imprimir"
    />

    <tabela-plano-contas
      :items="items"
      @editar="openModal"
      @atualizar="getPlanoContas"
    />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getPlanoContas"
    />

    <modal-plano-contas
      :exibir="modais.planoContas"
      :form="modais.planoContasDados"
      @atualizar="getPlanoContas"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import pdf from '@/common/utils/pdf';

// Services:
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

// Components:
import TituloPlanoContas from '@/views/contabil/plano-contas/components/TituloPlanoContas.vue';
import TabelaPlanoContas from './components/Tabela';
import ModalPlanoContas from './components/modal/Index';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    TituloPlanoContas,
    TabelaPlanoContas,
    Paginacao,
    ModalPlanoContas,
  },
  data() {
    return {
      items: [],
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      modais: {
        planoContas: false,
        planoContasDados: {},
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('CONTABIL_PLANO_CONTAS.CONTABIL') },
      { titulo: this.$t('CONTABIL_PLANO_CONTAS.PAGE_TITLE') },
    ]);
    this.getPlanoContas();
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getPlanoContas() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodosPaginado(this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE IMPRESSÃO:
    imprimir() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.imprimirPlanoContas()
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
