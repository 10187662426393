<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <input-text
        ref="estrutural"
        v-model="form.estrutural"
        type="text"
        :label="$t('CONTABIL_PLANO_CONTAS.ESTRUTURAL')"
        :placeholder="$t('CONTABIL_PLANO_CONTAS.ESTRUTURAL')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('CONTABIL_PLANO_CONTAS.DESCRICAO')"
        :placeholder="$t('CONTABIL_PLANO_CONTAS.DESCRICAO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoConta"
        v-model="form.tipoPlanoContaId"
        :label="$t(`CONTABIL_PLANO_CONTAS.TIPO_CONTA`)"
        :options="opcoes.tipos"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoNatureza"
        v-model="form.tipoNaturezaId"
        :label="$t(`CONTABIL_PLANO_CONTAS.TIPO_NATUREZA`)"
        :options="opcoes.naturezas"
        required
      />
    </div>
    <div class="col-12">
      <input-text
        ref="export"
        v-model="form.export"
        type="number"
        :label="$t('CONTABIL_PLANO_CONTAS.EXPORT')"
        :placeholder="$t('CONTABIL_PLANO_CONTAS.EXPORT')"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// COmponents:
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.getTipos();
    this.getNaturezas();
    this.$emit('refs', this.$refs);
  },
  data() {
    return {
      opcoes: {
        naturezas: [],
        tipos: [],
      },
    };
  },
  methods: {
    getTipos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-plano-contas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getNaturezas() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-naturezas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.naturezas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
