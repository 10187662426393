<template>
  <topbar
    :titulo="$t('CONTABIL_PLANO_CONTAS.PAGE_TITLE')"
    :subtitulo="$t('CONTABIL_PLANO_CONTAS.PAGE_DESCRIPTION')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex flex-md-row flex-column justify-content-between ml-md-4">
        <b-button variant="info" class="mr-md-2 mb-2 mb-md-0"  @click="gerarPlanosContas">
          {{ $t('CONTABIL_PLANO_CONTAS.IMPRIMIR_PLANO_CONTAS') }}
        </b-button>

        <b-button variant="info"  @click="cadastrar">
          {{ $t('GERAL.CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>

<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('CONTABIL_PLANO_CONTAS.CONTABIL') },
        { titulo: this.$t('CONTABIL_PLANO_CONTAS.PAGE_TITLE') },
      ],
    };
  },
  methods: {
    gerarPlanosContas() {
      this.$emit('gerar-plano-contas');
    },
    cadastrar() {
      this.$emit('cadastrar', 'planoContas');
    },
  },
};
</script>
