<template>
  <modal
    id="modalPlanoContas"
    :titulo="titulo"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-plano-conta :form="form" @refs="getRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormPlanoConta from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormPlanoConta,
  },
  data() {
    return {
      refs: [],
    };
  },
  computed: {
    titulo() {
      return !this.form.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    salvar() {
      if (!this.validarFormulario()) return;
      !this.form.id ? this.criar() : this.editar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_CRIADO').formatUnicorn({
              item: this.$t('CONTABIL_PLANO_CONTAS.PLANO_CONTA'),
            })
          );
          this.atualizarTabela();
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.editar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_EDITADO').formatUnicorn({
              item: this.$t('CONTABIL_PLANO_CONTAS.PLANO_CONTA'),
            })
          );
          this.atualizarTabela();
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    atualizarTabela() {
      this.$emit('atualizar');
    },
    fecharModal() {
      this.$emit('fechar', 'planoContas');
    },
  },
};
</script>
